@import "../variables.css";

.btn {
    min-width: 150px;      /* Minimum width */
    min-height: 40px;      /* Minimum height */
    padding: 1rem 2rem;
    border-radius: 25px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 18px; /* Default font size */
    transition: all 0.3s ease-out; /* Smooth hover transition */
    background-color: var(--button-color);
    color: var(--text-color);
    white-space: nowrap;
}

.btn:hover {
    background: var(--button-hover);
    color: var(--text-color);
    transform: translateY(-5px);
}

/* Primary button style */
.btn--primary {
    background-color: var(--button-color);
    color: var(--text-color);
}

/* Medium size button */
.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

/* Large size button */
.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

/* Hover effect for large and medium buttons */
.btn--large:hover,
.btn--medium:hover {
    background: var(--button-hover);
    color: var(--text-color);
}

/* Responsive adjustments for small screens */
@media (max-width: 720px) {
    .btn {
        font-size: 16px;  /* Adjust font size for smaller screens */
        padding: 6px 15px; /* Adjust padding for smaller screens */
    }

    .btn--medium {
        font-size: 16px;  /* Adjust font size for medium button */
        padding: 6px 15px; /* Adjust padding for medium button */
    }

    .btn--large {
        font-size: 18px;  /* Adjust font size for large button */
        padding: 8px 20px; /* Adjust padding for large button */
    }
}

/*!* Responsive adjustments for extra small screens (phones in portrait) *!*/
/*@media (max-width: 480px) {*/
/*    .btn {*/
/*        font-size: 12px;  !* Even smaller font size *!*/
/*        padding: 5px 12px; !* Smaller padding *!*/
/*    }*/

/*    .btn--medium {*/
/*        font-size: 14px;  !* Adjust font size for medium button *!*/
/*        padding: 5px 12px; !* Adjust padding for medium button *!*/
/*    }*/

/*    .btn--large {*/
/*        font-size: 16px;  !* Adjust font size for large button *!*/
/*        padding: 6px 15px; !* Adjust padding for large button *!*/
/*    }*/
/*}*/
