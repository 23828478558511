@import '../variables.css';

/* Header styles */
.header {
    background-color: var(--primary-color);  /* Blue background */
    color: var(--text-color);
    /*padding: 30px 0;*/
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;  /* Center the content horizontally */
    align-items: center;      /* Vertically center the header */
}

/* Container for header items */
.header-container {
    display: flex;
    justify-content: space-between;  /* Space between items: logo on the left, phone on the right */
    align-items: center;             /* Center items vertically */
    width: 100%;
    max-width: 1200px;  /* Max width to avoid it becoming too wide */
    padding: 0 20px;  /* Padding on both sides */
}

.logo {
    /*background-color: red;*/
    /*width: 100%;*/
    height: 100%;
}

/* Left-aligned logo */
.logo img {
    width: 220px;  /* Adjust logo size */
    height: 100px;
}

/* Right-aligned phone number */
.phone-number {
    display: flex;
    align-items: center;  /* Vertically center the phone number */
    text-align: right;
}

.phone-number i {
    margin-right: 10px;  /* Space between the icon and the number */
    font-size: 1.4rem;
    color: var(--secondary-color);
}

.phone-number p {
    margin: 0;
    font-size: 1.3rem;
    letter-spacing: 1px;
}

.phone-number p:hover {
    color: var(--secondary-color);
    transition: 0.3s ease-out;
}

/* Media query for mobile screens */
@media (max-width: 520px) {
    .header {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .header-container {
        flex-direction: column;  /* Stack items vertically on small screens */
        align-items: center;     /* Center the items horizontally */
        text-align: center;      /* Center text */
    }

    .logo img {
        /*background-color: red;*/
        /*height: 90px;*/
        width: 220px;  /* Adjust logo size for mobile */
        /*margin-bottom: 10px;  !* Add space below logo *!*/
    }

    .phone-number {
        /*margin-bottom: 10px;  !* Add margin between phone number and logo *!*/
        display: none;
    }
}
