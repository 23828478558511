.service {
    /*background-color: red;*/
    /*padding: 4rem 0;*/
    padding-top: 4rem;
    width: 100%;
    /*height: 500px;*/
    display: flex;
    justify-content: center;  /* Center the content horizontally */
    /*align-items: center;      !* Vertically center the header *!*/
}

.service-container {
    /*background-color: purple;*/
    /*display: flex;*/
}

.text-container {
    max-width: 1200px;
    margin: 0 auto;   /* Center the container horizontally */
    padding: 2rem;    /* Add padding inside the container */
    text-align: center; /* Center the text inside the container */
    /*background-color: #f9f9f9; !* Light background for the text section *!*/
}

.text-title {
    font-size: 1.25rem; /* Smaller title */
    font-weight: normal;   /* Slightly bolder text */
    color: var(--secondary-color);       /* Dark gray color for contrast */
    margin-bottom: 1rem; /* Space between title and heading */
}

.text-heading {
    font-size: 2.5rem;  /* Large heading size */
    font-weight: bold;   /* Bold heading */
    color: var(--primary-color);     /* Darker color for the heading */
    margin-bottom: 1.5rem; /* Space between heading and description */
}

.text-description {
    font-size: 1rem;    /* Normal text size */
    line-height: 1.5;   /* Increase line height for better readability */
    color: var(--text-color-dark);        /* Light gray text */
    /*text-align: center; !* Justify the text for a more polished look *!*/
}

.grid-container {
    /*background-color: purple;*/
    padding: 1rem;
    max-width: 1400px;
}

.grid {
    /*background-color: blue;*/
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 3rem;
    /*width: 400px;*/
    /*height: 100%;*/
}

.grid-item {
    /*background-color: green;*/
    width: 100%;
    height: 100%;
    /*text-align: center;*/
    align-self: center;
    justify-self: center;
}

/* Media query for screen widths smaller than 768px (tablet size and below) */
@media (max-width: 1000px) {
    .grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns */
        grid-template-rows: repeat(3, 1fr); /* 3 rows */
        gap: 2rem;
    }
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: repeat(1, 1fr); /* 1 columns */
        grid-template-rows: repeat(6, 1fr); /* 6 rows */
        gap: 1rem;
    }

    .text-container {
        /*padding: 0;*/
        padding-top: 0;
    }

    .text-description {
        text-align: left;
    }
}

