@import '../variables.css';


.navbar {
    background: var(--background-color);
    height: 80px;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    align-items: center;
    font-size: 1.2rem;
    position: sticky; /* make nav bar stay on top, relative doesn't*/
    top: 0;
    z-index: 999;
    border: black solid 1px;
    /*width: 100%;*/
}

.navbar-container {
    display: flex;
    /*justify-content: center;*/
    justify-content: space-between;
    align-items: center;
    height: 80px;
    max-width: 1200px;
    width: 100%;
    position: relative;
}

.nav-menu {
    display: flex; /* Change from grid to flex for better control */
    justify-content: flex-start; /* Align menu items to the right */
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 2rem;
    height: 100%;
    position: relative;
}

.nav-links::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 3px;
    background-color: var(--primary-color);
    transition: width 0.4s ease-out, left 0.4s ease-out;
}

.nav-links:hover::after {
    width: 100%;
    left: 0;
}

.nav-links:hover {
    color: var(--secondary-color);
}

/* Active link style */
.nav-links.active {
    color: var(--secondary-color);  /* Change text color for active link */
}

.nav-links.active::after {
    width: 100%; /* Always show the underline when selected */
    left: 0; /* Ensure it starts at the left edge */
}


.fa-bars {
    color: var(--primary-color);
    font-size: 2rem;
}

.menu-icon {
    display: none;
}

.schedule-button Button{
    /*letter-spacing: 1px;*/
}

a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit the color from the parent element */
}


@media screen and (max-width: 768px) {

    /* Make sure the parent container of the navbar is relative */
    .navbar-container {
        position: relative;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    /* Menu should be hidden initially */
    .nav-menu {
        display: none;
        flex-direction: column;  /* Stack the links vertically */
        width: 100%;             /* Ensure the menu spans the full width */
        background-color: var(--primary-color); /* Dark background for the menu */
        position: absolute;
        top: 80px; /* Starts right below the navbar */
        left: 0;
        transition: all 0.5s ease;
        /*list-style: none; !* Remove bullet points *!*/
        padding: 0; /* Remove any default padding */
        border: black solid 1px;
    }

    /* When the menu is active, it becomes visible */
    .nav-menu.active {
        display: flex;
        opacity: 1;
        z-index: 1; /* Ensure it's above other content */
    }

    /* Menu icon style */
    .menu-icon {
        display: block;
        /*position: absolute;*/
        top: 20px;  /* Adjust the icon position within the navbar */
        left: 20px; /* Align the menu icon to the left */
        font-size: 1.8rem;
        cursor: pointer;
    }

    /* Icon styles */
    .fa-bars {
        color: var(--primary-color);
    }

    .fa-xmark {
        color: var(--primary-color);
    }

    /* Style for nav links */
    .nav-menu li {
        text-align: center;
        width: 100%; /* Ensure each link spans full width */
        height: 50px;
    }

    .nav-menu li a {
        font-size: 1rem;
        display: flex;
        padding-left: 20px;
        /*padding: 0;*/
        /*width: 100%; !* Links span the full width *!*/
        color: var(--primary-color); /* Set link color */
        text-decoration: none;
        background-color: var(--background-color); /* Match the background */
    }

    /* Hover effect for nav links */
    .nav-menu li a:hover {
        /*background-color: white; !* Lighten background on hover *!*/
        color: var(--secondary-color); /* Darken text on hover */
    }

    /* Button for mobile */
    .schedule-button Button{
        width: 100%;
        font-size: 1rem;
    }
}



