@import "../variables.css";

.footer-container {
    background-color: var(--footer-color);
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    /*padding: 24px;*/
    /*color: #fff;*/
}

.footer-logo img {
    width: 320px;
    height: 140px;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
}

.footer-link-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    /*width: 460px;*/
    box-sizing: border-box;
    /*width: 100%;*/
    /*display: flex; !* Flexbox to align the icon and text *!*/
    /*align-items: center; !* Center align the icon and text vertically *!*/
    /*white-space: nowrap; !* Prevent the text from wrapping *!*/
    /*overflow: hidden; !* Prevent overflow in case of long text *!*/
}

.footer-link-items h2 {
    margin-bottom: 2rem;
    color: var(--text-color);
}

.footer-link-items a {
    color: var(--text-color);
    text-decoration: none;
    margin-bottom: 1.5rem;
}

.footer-link-items p {
    color: var(--text-color);
    text-decoration: none;
    margin-bottom: 1rem;
}

.footer-link-items i {
    color: var(--secondary-color);
    margin-right: 1rem;
}

.footer-link-items a:hover,
.footer-link-items p:hover {
    color: var(--secondary-color);
    transition: 0.3s ease-out;
}

.footer-copyright {
    color: var(--text-color);
    padding-top: 5rem;
    /*margin-bottom: 16px;*/
    white-space: nowrap; /* Prevent the text from wrapping */
}

@media screen and (max-width: 768px) {
    .footer-links {
        padding-top: 2rem;

    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
        padding-left: 2rem;
        /*margin-left: 1rem;*/

    }

    .footer-copyright {
        font-size: 0.8rem; /* Further reduce font size on very small screens */
    }

    .footer-logo {
        margin-bottom: 0;
    }

    .footer-logo img {
        width: 300px;
        height: 130px;
    }
}
