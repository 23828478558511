@import "variables.css";

body {
  background: var(--background-color);
}

html {
  scroll-behavior: smooth;
}

/*Soft Blue: #4A90E2
Mint Green: #A5D6A7
Light Gray: #F5F5F5
Warm Beige: #F1E1C6
Dark Blue for Text: #333F48
Accent Yellow: #FFDD57*/