.card {
    /*width: 400px;*/
    /*min-height: 300px;*/
    /*height: 550px;*/
    /*width: 400px;*/
    height: 100%;
    /*border: 1px solid #ddd;*/
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--card);
    transition: all 0.3s ease-out; /* Smooth hover transition */
}

.card:hover {
    transform: translateY(-10px);
}

.card:hover .card-icon,
.card:hover .card-icon-image {
    background-color: var(--button-hover); /* Optional: Change background color of the icon */
}

.card-image-wrapper {
    position: relative;
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-icon-image {
    position: absolute;
    width: 50px;
    text-align: center;
    top: 150px; /* Adjust this to move the icon down or up */
    left: 20px; /* Adjust this to move the icon left or right */
    color: white; /* Icon color */
    font-size: 2.5rem; /* Icon size */
    background-color: var(--button-color); /* Optional: semi-transparent background for icon */
    padding: 0.5rem;
    border-radius: 10px; /* Makes the icon background circular */
    transition: color 1s ease, background-color 1s ease; /* Smooth transition for color and background */
}

.card-icon {
    position: relative;
    width: 50px;
    text-align: center;
    top: 20px; /* Adjust this to move the icon down or up */
    left: 30px; /* Adjust this to move the icon left or right */
    color: white; /* Icon color */
    font-size: 2.5rem; /* Icon size */
    background-color: var(--secondary-color); /* Optional: semi-transparent background for icon */
    padding: 0.5rem;
    border-radius: 10px; /* Makes the icon background circular */
    transition: color 1s ease, background-color 1s ease; /* Smooth transition for color and background */
}

.card-title {
    /*margin: 16px;*/
    margin: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-color);
}

.card-description {
    /*margin: 0 16px 16px;*/
    margin: 2rem;
    font-size: 1rem;
    line-height: 1.6;
    color: var(--text-color-dark);
}

